<template>
    <div class="space-y-1 w-full mt-4">
        <div v-for="product in $store.state.cart.items" :key="product.id">
            <div class="space-y-2" :class="{ 'p-1' : inCart, 'bg-red-50 p-2': product.extra_info.is_sold}">
                <div class="flex items-stretch">
                    <div class="w-full h-[55px] basis-1/12" :class="{ 'basis-2/12' : inCart }">
                        <div v-if="product.image">
                            <img :src="product.image" :alt="product.alt">
                        </div>
                        <div v-else class="bg-topolis-gray-100 rounded-md p-2 h-full flex items-center justify-center">
                            <div v-if="product.options.category.parent_id === 351">
                                <img :src="getClothesImage(product)"
                                     :alt="getClothesAlt(product)"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 213">
                                <img src="/assets/categories/toys.svg"
                                     alt="minitopolis-no-image-toy-placeholder"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 144">
                                <img :src="getEquipmentImage(product)"
                                     :alt="getEquipmentAlt(product)"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 221">
                                <img src="/assets/categories/jacket.svg"
                                     alt="minitopolis-no-image-jacket-placeholder"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 620">
                                <img :src="getInteriorImage(product)"
                                     :alt="getInteriorAlt(product)"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 376">
                                <img :src="getAccessoriesImage(product)"
                                     :alt="getAccessoriesAlt(product)"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 621">
                                <img src="/assets/categories/footwear.svg"
                                     alt="minitopolis-no-image-footwear-placeholder"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.parent_id === 377">
                                <img src="/assets/categories/books-games.svg"
                                     alt="minitopolis-no-image-books-games-placeholder"
                                     class="bg-no-repeat">
                            </div>
                            <div v-else-if="product.options.category.id === 132 || product.options.category.id === 378">
                                <img src="/assets/categories/other.svg"
                                     alt="minitopolis-no-image-other-placeholder"
                                     class="bg-no-repeat">
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between text-left w-full ml-3 my-1">
                        <div class="flex flex-col justify-between gap-1 leading-3 mr-4">
                            <p class="font-medium">
                                <span class="mr-1">{{ product.title }}</span>
                                <span class="status-badge turquoise-badge !px-1 !py-0 !text-[10px] !leading-4"
                                      v-if="product.options.size">
                                    str. {{ product.options.size.name }}
                                </span>
                            </p>
                            <div class="inline-flex items-center gap-1 font-medium">
                                <!-- - Button for decreasing -->
                                <button @click="decreaseQuantity(product)" class="group -mt-0.5">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         class="flex-shrink-0 min-w-0 w-4 h-4 text-topolis-black fill-transparent group-hover:fill-red-300 rounded-full"
                                         viewBox="0 0 24 24">
                                        <g transform="translate(-172 -252)">
                                            <path d="M10,20A10,10,0,1,0,0,10,10.029,10.029,0,0,0,10,20Z"
                                                  transform="translate(173.92 254)" fill="fillCurrent"
                                                  stroke="currentColor"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                            <path d="M0,0H8" transform="translate(179.92 264)" fill="none"
                                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5" />
                                        </g>
                                    </svg>
                                </button>

                                <!-- Show quantity -->
                                <span class="text-sm">{{ product.quantity }} stk.</span>

                                <!-- + Button for increasing -->
                                <button @click="increaseQuantity(product)"
                                        class="group -mt-0.5 disabled:cursor-not-allowed disabled:opacity-40"
                                        :disabled="reachedStockLimit(product)">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         class="flex-shrink-0 min-w-0 w-4 h-4 text-topolis-black fill-transparent group-hover:fill-topolis-purple-100 rounded-full group-disabled:text-gray-400 group-disabled:fill-gray-50"
                                         viewBox="0 0 24 24">
                                        <g transform="translate(-108 -252)">
                                            <path d="M10,20A10,10,0,1,0,0,10,10.029,10.029,0,0,0,10,20Z"
                                                  transform="translate(110 254)" fill="fillCurrent"
                                                  stroke="currentColor"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                            <path d="M0,0H8" transform="translate(116 264)" fill="none"
                                                  stroke="currentColor"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                            <path d="M0,8V0" transform="translate(120 260)" fill="none"
                                                  stroke="currentColor"
                                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-col items-end justify-between gap-1">
                            <div v-if="product.extra_info.discount" class="flex items-center gap-1">
                                <price class="font-light text-xs line-through"
                                       :price="product.extra_info.original_price"></price>
                                <price class="font-bold text-sm" :price="product.price"></price>
                            </div>
                            <div v-if="!product.extra_info.discount" class="flex">
                                <price class="font-bold text-sm" :price="product.price"></price>
                            </div>
                            <button @click="removeFromCart(product)"
                                    class="remove-item-cart inline-flex text-xxs font-medium underline hover:text-red-700">
                                Fjern
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-2 py-1 bg-red-200 text-red-900 text-xs" v-if="product.extra_info.is_sold">
                Produktet er desværre blevet solgt :(. Kontakt os for at høre om vi har noget lignende.
            </div>
        </div>
    </div>
</template>
<script>
    import Price from "./Price.vue";

    export default {
        name: "product-list",
        components: { Price },
        props: {
            inCart: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                timer: null
            };
        },
        mounted() {
            this.timer = setInterval(() => {
                this.checkProductAvailability();
            }, 10000);
        },
        beforeUnmount() {
            clearInterval(this.timer);
        },
        watch: {
            "$store.state.cart.items": {
                handler(newValue, oldValue) {
                    console.log("10hiart items changed:", { newValue, oldValue });
                },
                immediate: true, // Logs the current value right away
                deep: true // Ensures changes within array/object are also detected
            }
        },
        computed: {
            someIsSold() {
                this.$nextTick(() => {
                    return this.$store.state.cart.items.some(item => item.extra_info.is_sold);
                });
            }
        },
        methods: {
            increaseQuantity(product) {
                this.$store.dispatch("addToCart", { variant: product.options.variant });
            },
            reachedStockLimit(product) {
                const cartItems = this.$store.state.cart.items;

                // Find this product in the cart
                const cartProduct = Object.values(cartItems).find(
                    (item) => item.id === product.id
                );

                console.log("hh", cartProduct);

                // Compare quantity with stock level
                return cartProduct
                    ? cartProduct.quantity >= cartProduct.extra_info.stock_levet
                    : false;

            },
            // Decrease the quantity of a product using updateCartQuantity
            decreaseQuantity(product) {
                if (product.quantity > 1) {
                    this.$store.dispatch("updateCartQuantity", {
                        hash: product.hash,
                        newQuantity: product.quantity - 1
                    });
                } else {
                    // If quantity reaches 1, removing the item entirely
                    this.$store.dispatch("removeFromCart", product.hash);
                }
            },
            checkProductAvailability() {
                axios.get("/check-product-availability").then((response) => {
                    this.$nextTick(() => {
                        this.$store.commit("setCart", response.data.result);
                    });
                });
            },
            removeFromCart(product) {
                this.$store.dispatch("removeFromCart", product.hash);
            },
            getClothesImage(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "/assets/categories/mama-clothes.svg"
                        : "/assets/categories/clothes.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/clothes.svg"; // Default fallback
            },
            getClothesAlt(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "MAMA tøj"
                        : "Børnetøj"; // Default to 'children' or fallback
                }
                return "MAMA tøj"; // Default fallback
            },
            getEquipmentImage(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "/assets/categories/belt.svg"
                        : "/assets/categories/equipment.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/equipment.svg"; // Default fallback
            },
            getEquipmentAlt(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "Udstyr til MAMA"
                        : "Udstyr til børn"; // Default to 'children' or fallback
                }
                return "Udstyr til børn"; // Default fallback
            },
            getAccessoriesImage(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "/assets/categories/mama-accessories.svg"
                        : "/assets/categories/accessories.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/accessories.svg"; // Default fallback
            },
            getAccessoriesAlt(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "Accessories til MAMA"
                        : "Accessories til børn"; // Default to 'children' or fallback
                }
                return "Accessories til børn"; // Default fallback
            },
            getInteriorImage(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "/assets/categories/mama-interior.svg"
                        : "/assets/categories/interior.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/interior.svg"; // Default fallback
            },
            getInteriorAlt(product) {
                if (product.extra_info.target_segment) {
                    return product.extra_info.target_segment === "mama"
                        ? "Interiør til MAMA"
                        : "Interiør til børn"; // Default to 'children' or fallback
                }
                return "Interiør til børn"; // Default fallback
            }
        }
    };
</script>
