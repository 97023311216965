<template>
    <div>
        <label for="numParcels">Number of Parcels:</label>
        <input type="number" v-model="numParcels" min="1" @change="adjustParcels" />

        <div v-for="(parcel, index) in parcels" :key="index" class="parcel">
            <h3>Parcel {{ index + 1 }}</h3>
            <label for="weight">Weight (in grams):</label>
            <input
                type="number"
                v-model="parcel.weight"
                placeholder="Enter weight"
                min="0"
            />
        </div>

        <button @click="submitParcels">Submit Parcels</button>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            numParcels: 1,
            parcels: [{ weight: 0 }],
        };
    },
    methods: {
        adjustParcels() {
            if (this.numParcels > this.parcels.length) {
                for (let i = this.parcels.length; i < this.numParcels; i++) {
                    this.parcels.push({ weight: 0 });
                }
            } else if (this.numParcels < this.parcels.length) {
                this.parcels.splice(this.numParcels);
            }
        },
        submitParcels() {
            const orderId = 123; // Replace with actual order ID
            axios.post(`/orders/${orderId}/parcels`, { parcels: this.parcels })
                .then(response => {
                    console.log('Parcels saved successfully', response.data);
                })
                .catch(error => {
                    console.error('Error saving parcels', error.response.data);
                });
        },
    },
};
</script>

<style scoped>
.parcel {
    margin-bottom: 1rem;
}
</style>
