<template>
    <div class="flex flex-col lg:flex-row items-start justify-center gap-4 xs:gap-8 lg:gap-16 lg:mt-8">
        <div class="flex flex-col basis-1/12">
            <div class="flex flex-col gap-1 mb-6" v-if="showSegments">
                <h5 class="font-bold mb-2">Univers</h5>
                <a :href="targetSegmentLink('boern')"
                   class="text-sm font-normal text-black hover:text-black">Børneunivers</a>
                <a :href="targetSegmentLink('mama')"
                   class="text-sm font-normal text-black hover:text-black">MAMA univers</a>
            </div>
            <!--            <div>-->
            <!--                <h5 class="font-bold mb-2">Kategorier</h5>-->
            <!--                <div v-for="sideCategory in categories" :key="sideCategory.id">-->
            <!--                    <a :href="categoryLink(sideCategory)"-->
            <!--                       class="text-sm font-normal text-black hover:text-black">{{ sideCategory }}</a>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
        <div
            class="flex items-center justify-center relative flex-wrap gap-12 max-w-10xl mx-auto w-full basis-11/12">
            <product-filters :categories="categories" :brands="brands" :sizes="sizes"
                             :colors="colors"
                             :patterns="patterns" @filters-changed="applyFilters"></product-filters>
            <loading-spinner v-if="loading" :loading="loading" />
            <DataView v-show="!loading" :value="products" :sortOrder="sortOrder" :sortField="sortField"
                      :layout="layout" class="product-grid-view" paginator :rows="perPage" :totalRecords="totalProducts"
                      @page="onPageChange">
                <template #header>
                    <div>
                        <p>{{ totalProducts }} varer</p>
                    </div>
                    <!--                    <div class="flex items-center justify-between gap-4">-->
                    <!--                        <Select v-model="sortKey" :options="sortOptions" optionLabel="label"-->
                    <!--                                placeholder="Sort By Price"-->
                    <!--                                @change="onSortChange($event)" class="form-select-admin" />-->
                    <!--                            <SelectButton v-model="layout" :options="options" :allowEmpty="false">-->
                    <!--                                <template #option="{ option }">-->
                    <!--                                    <i :class="[option === 'list' ? 'pi pi-bars' : 'pi pi-table']" />-->
                    <!--                                </template>-->
                    <!--                            </SelectButton>-->
                    <!--                    </div>-->
                    <!--                </template>-->
                    <!--                    <template #list="slotProps">-->
                    <!--                        <div class="flex flex-col">-->
                    <!--                            <div v-for="(item, index) in slotProps.items" :key="index">-->
                    <!--                                <div class="flex flex-col sm:flex-row sm:items-center p-2 gap-4"-->
                    <!--                                     :class="{ 'border-t border-surface-200 dark:border-surface-700': index !== 0 }">-->
                    <!--                                    <product-list-card :variant="item" :admin-page="true" />-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                </template>
                <template #grid="slotProps">
                    <div
                        class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-2 gap-y-4 xs:gap-4 sm:gap-y-6 lg:gap-y-8">
                        <div v-for="(item, index) in slotProps.items" :key="index" class="p-2">
                            <product-card :variant="item" :admin-page="false" />
                            <!--                        <div-->
                            <!--                            class="p-6 border border-surface-200 dark:border-surface-700 bg-surface-0 dark:bg-surface-900 rounded flex flex-col">-->
                            <!--                            <div class="bg-surface-50 flex justify-center rounded p-4">-->
                            <!--                                <div class="relative mx-auto">-->
                            <!--                                    <img class="rounded w-full"-->
                            <!--                                         src="/assets/shop/minitopolis-accessories-placeholder.jpg"-->
                            <!--                                         alt="overtøj" style="max-width: 300px" />-->
                            <!--                                    &lt;!&ndash;                                    <Tag :value="item.inventoryStatus" :severity="getSeverity(item)"&ndash;&gt;-->
                            <!--                                    &lt;!&ndash;                                         class="absolute dark:!bg-surface-900" style="left: 4px; top: 4px"></Tag>&ndash;&gt;-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <!--                            <div class="pt-6">-->
                            <!--                                <div class="flex flex-row justify-between items-start gap-2">-->
                            <!--                                    <div>-->
                            <!--                                        <span-->
                            <!--                                            class="font-medium text-surface-500 dark:text-surface-400 text-sm">{{ item.category-->
                            <!--                                            }}</span>-->
                            <!--                                        <div class="text-lg font-medium mt-1">{{ item.name }}</div>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="bg-surface-100 p-1" style="border-radius: 30px">-->
                            <!--                                        <div class="bg-surface-0 flex items-center gap-2 justify-center py-1 px-2"-->
                            <!--                                             style="border-radius: 30px; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)">-->
                            <!--                                            <span class="text-surface-900 font-medium text-sm">{{ item.rating }}</span>-->
                            <!--                                            <i class="pi pi-star-fill text-yellow-500"></i>-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                                <div class="flex flex-col gap-6 mt-6">-->
                            <!--                                    <div v-if="item.discount" class="flex flex-col">-->
                            <!--                                        <div class="flex">-->
                            <!--                                            <p class="text-sm xs:text-base font-medium text-red-500">-->
                            <!--                                                <price :price="item.discounted_price"></price>-->
                            <!--                                            </p>-->
                            <!--                                        </div>-->
                            <!--                                        <div class="flex items-center -mt-0.5">-->
                            <!--                                            <p class="text-gray-500 line-through text-xs mr-1">-->
                            <!--                                                <price :price="item.price"></price>-->
                            <!--                                            </p>-->
                            <!--                                            <p class="text-xxs xs:text-xs text-red-500 font-medium">-{{ item.discount-->
                            <!--                                                }}%</p>-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->
                            <!--                                    <div v-else-->
                            <!--                                         class="flex text-sm xs:text-base font-medium text-topolis-blue-700 mr-2 xs:mr-0">-->
                            <!--                                        <price :price="item.price"></price>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="flex gap-2">-->
                            <!--                                        <Button icon="pi pi-shopping-cart" label="Buy Now"-->
                            <!--                                                :disabled="item.inventoryStatus === 'OUTOFSTOCK'"-->
                            <!--                                                class="flex-auto whitespace-nowrap"></Button>-->
                            <!--                                        <Button icon="pi pi-heart" outlined></Button>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <!--                        </div>-->
                        </div>
                    </div>
                </template>
            </DataView>
            <!--            <div v-if="!loading" class="flex flex-col items-center gap-8">-->
            <!--                <div-->
            <!--                    class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-x-2 gap-y-4 xs:gap-4 sm:gap-y-6 lg:gap-y-8">-->
            <!--                    <product-card v-for="variant in products" :key="variant.id"-->
            <!--                                  :variant="variant"-->
            <!--                                  :new-products="newProducts" :current-segment="currentSegment" />-->

            <!--                </div>-->
            <!--                <div class="flex">-->
            <!--                    <loading-button v-if="moreProducts" type="button" :loading="loadingMore"-->
            <!--                                    :disabled="!moreProducts"-->
            <!--                                    class="button button-purple" @click="loadMore">-->
            <!--                        Load more-->
            <!--                    </loading-button>-->
            <!--                    <p v-else-if="products.length > 30 && !moreProducts" class="italic font-medium">No more products to-->
            <!--                        load</p>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>

</template>

<script>
    import ProductCard from "./ProductCard.vue";
    import LoadingButton from "../Pages/Common/LoadingButton.vue";
    import LoadingSpinner from "../Pages/Common/LoadingSpinner.vue";
    import ProductFilters from "../Components/ProductFilters.vue";
    import ProductListCard from "./ProductListCard.vue";
    import DataView from "primevue/dataview";
    import Select from "primevue/select";
    import SelectButton from "primevue/selectbutton";

    export default {
        components: {
            ProductListCard,
            ProductCard, LoadingButton, LoadingSpinner, ProductFilters, DataView, Select, SelectButton
        },
        props: {
            slugProducts: {
                type: Boolean,
                default: false,
                required: false
            },
            newProducts: {
                type: Boolean,
                default: false,
                required: false
            },
            route: {
                type: String,
                required: true
            },
            slug: {},
            categories: {},
            brands: {},
            sizes: {},
            colors: {},
            patterns: {},
            isParentCategory: {
                type: Boolean,
                default: false,
                required: false
            },
            currentSegment: {
                type: String,
                required: false,
                default: "boern"
            },
            showSegments: {
                type: Boolean,
                default: false,
                required: false
            },
            productPerPage: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                products: [],
                totalProducts: 0, // Add totalProducts to data
                nextPage: 1,
                targetSegment: null,
                perPage: this.productPerPage,
                loading: false,
                loadingMore: false,
                isLoading: false,
                sortKey: null,
                sortOrder: null,
                sortField: null,
                sortOptions: [
                    { label: "Price High to Low", value: "!price" },
                    { label: "Price Low to High", value: "price" }
                ],
                layout: "grid",
                options: ["list", "grid"]
            };
        },
        methods: {
            categoryLink(category) {
                if (category.parent === null) {
                    return `/shop/${this.currentSegment}/${category.slug}`;
                } else {
                    return `/shop/${this.currentSegment}/${category.parent.slug}/${category.slug}`;
                }
            },
            targetSegmentLink(segment) {
                return `/shop/${segment}/${this.slug}`;
            },
            fetchProducts(page = 1) {
                this.loading = true;
                // this.loadingMore = true;
                const params = {
                    ...this.filters,
                    page: page,
                    perPage: this.perPage
                };
                console.log("Route:", this.route);
                if (this.slugProducts) {
                    params.slug = this.slug;
                    params.parentCategory = this.isParentCategory;
                }
                axios.get(this.route, { params })
                    .then(response => {
                        console.log("que?", response.data.products);
                        this.products = this.products.concat(response.data.products);
                        this.totalProducts = response.data.total; // Set totalProducts from response
                        // this.moreProducts = response.data.has_more; // Change this line
                        this.targetSegment = response.data.targetSegment;
                        // this.nextPage++;
                        // this.loadingMore = false;
                        this.loading = false;
                    });
            },
            onPageChange(event) {
                const page = event.page + 1;
                this.fetchProducts();
            },
            // updateUrl(page) {
            //     const currentUrl = new URL(window.location.href);
            //     if (page === 1) {
            //         currentUrl.searchParams.delete("p");
            //     } else {
            //         currentUrl.searchParams.set("p", page);
            //     }
            //     window.history.pushState({}, "", currentUrl);
            // },
            applyFilters(newFilters) {
                console.log("filter.", newFilters);
                this.filters = newFilters;
                this.products = [];  // reset products array
                this.nextPage = 1;   // reset page counter
                this.fetchProducts();     // fetch products
            },
            onSortChange(event) {
                const value = event.value.value;
                const sortValue = event.value;

                if (value.indexOf("!") === 0) {
                    this.sortOrder = -1;
                    this.sortField = value.substring(1, value.length);
                    this.sortKey = sortValue;
                } else {
                    this.sortOrder = 1;
                    this.sortField = value;
                    this.sortKey = sortValue;
                }
            }
        },
        mounted() {
            this.fetchProducts();
        }
    };

</script>

<style>
    .product-grid-view .p-dataview-content, .product-grid-view .p-dataview-header {
        background: transparent;
    }

    .product-grid-view .p-dataview-paginator-bottom {
        margin-top: 2rem;
    }

    .product-grid-view .p-dataview-paginator-bottom .p-paginator {
        background: transparent;
    }
</style>