<template>
    <div class="flex w-full">
        <Galleria
            :value="formattedImages"
            :responsiveOptions="responsiveOptions"
            :thumbnailsPosition="position"
            :numVisible="4"
            :showThumbnails="showThumbnails"
            :showItemNavigators="true"
            :showItemNavigatorsOnHover="true"
            :circular="true"
            class="product-gallery"
            :pt="{
                root: {
                    class: 'relative flex-1'
                },
                content: {
                    class: 'aspect-[4/3] justify-center w-full'
                },
                thumbnails: ''
            }">
            <template #item="slotProps">
                <img
                    :src="slotProps.item.itemImageSrc"
                    :alt="slotProps.item.alt"
                    :srcset="slotProps.item.srcSet"
                    sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, 1200px"
                    style="width: 100%; display: block" />
            </template>
            <template #thumbnail="slotProps">
                <img :src="slotProps.item.thumbnailImageSrc"
                     :alt="slotProps.item.alt"
                     class="!aspect-[4/3] !object-cover !h-[80px] sm:!h-[100px] md:h-auto !w-[125px] sm:!w-[150px] md:w-auto"
                     style="display: block;" />
            </template>
            <template #caption="slotProps" v-if="!showThumbnails">
                <p class="text-white italic">{{ slotProps.item.alt }}</p>
                <div class="text-xs mt-2 font-light">For nu, hvis du ønsker billeder af produktet, så kan du skrive en
                    mail til
                    os <span>
                        <a :href="`mailto:kontakt@minitopolis.dk?subject=Produktforespørgsel&body=Hey Team Minitopolis,%0D%0A%0D%0AJeg ønsker billeder af produktet med navnet: ${variant?.slug || 'Ingen variant'}.`"
                           class="mini-link underline !text-topolis-white">her</a></span>.
                </div>
            </template>
        </Galleria>
    </div>
</template>

<script>
    import Galleria from "primevue/galleria";

    export default {
        name: "ProductGallery",
        components: { Galleria },
        props: {
            images: {
                type: Object, // Expect an object structure
                required: true
            },
            variant: {
                type: Object,
                required: false,
                default: null
            }
        },
        data() {
            return {
                screenWidth: window.innerWidth, // Track screen width
                formattedImages: [],
                position: window.innerWidth > 768 ? "left" : "bottom", // Set initial position dynamically
                responsiveOptions: [
                    {
                        breakpoint: "1300px",
                        numVisible: 4
                    },
                    {
                        breakpoint: "768px",
                        numVisible: 3
                    },
                    {
                        breakpoint: "575px",
                        numVisible: 2
                    },
                    {
                        breakpoint: "375px",
                        numVisible: 1
                    }
                ]
            };
        },
        computed: {
            showThumbnails() {
                // If no images, then hide the thumbnails.
                return this.images && Object.keys(this.images).length > 0;
            }
        },
        watch: {
            screenWidth(newWidth) {
                // Update the position dynamically based on the screen width
                this.position = newWidth > 768 ? "left" : "bottom";
            }
        },
        mounted() {
            if (this.images && Object.keys(this.images).length > 0) {
                console.log("ppp", this.images);
                this.formatGroupedImages(this.images);
            } else {
                console.log("Received `images` prop is not an object:", this.images);
                this.assignDefaultImage();
            }

            // Add event listener to update `screenWidth` on window resize
            window.addEventListener("resize", this.updateScreenWidth);
        },
        beforeUnmount() {
            window.removeEventListener("resize", this.updateScreenWidth);
        },
        methods: {
            updateScreenWidth() {
                this.screenWidth = window.innerWidth; // Update screen width dynamically
            },
            formatGroupedImages(groupedImages) {
                // Transform the groupedImages object into an array usable by Galleria
                Object.values(groupedImages).forEach(imageGroup => {
                    if (Array.isArray(imageGroup)) {
                        const groupWithResponsiveSizes = {};

                        imageGroup.forEach(image => {
                            if (!groupWithResponsiveSizes[image.image_group_id]) {
                                // Prepare the entry for this group if it doesn't exist
                                groupWithResponsiveSizes[image.image_group_id] = {
                                    thumbnailImageSrc: "",
                                    itemImageSrc: "",
                                    srcSet: "",
                                    alt: image.alt || "Product Image"
                                };
                            }

                            if (image.responsive_size === "thumbnail") {
                                groupWithResponsiveSizes[image.image_group_id].thumbnailImageSrc = image.real_image_path;
                            } else if (["default", "medium", "mobile"].includes(image.responsive_size)) {
                                groupWithResponsiveSizes[image.image_group_id].itemImageSrc = image.real_image_path;
                                // Build `srcSet` for responsive sizes
                                groupWithResponsiveSizes[image.image_group_id].srcSet += `${image.real_image_path} ${this.mapResponsiveSizeToWidth(image.responsive_size)}w, `;
                            }
                        });

                        // Push the processed group to formattedImages
                        Object.values(groupWithResponsiveSizes).forEach(group => {
                            group.srcSet = group.srcSet.trimEnd(", "); // Remove trailing comma
                            this.formattedImages.push(group);
                        });
                    } else {
                        console.warn("Image group is not an array:", imageGroup);
                    }
                });
            },
            mapResponsiveSizeToWidth(size) {
                // Map the responsive size to a width (you can modify these values as needed)
                const sizeMap = {
                    default: 1200,
                    medium: 800,
                    mobile: 480
                };
                return sizeMap[size] || 400; // Fallback width
            },
            assignDefaultImage() {
                console.log("what is variant", this.variant.product.category.parent_id);

                const parentId = this.variant.product.category.parent_id; // Access the category parent_id
                const categoryId = this.variant.product.category.id;
                const targetSegment = this.variant.product.target_segment;

                // Default placeholder image logic based on category parent_id or id
                let placeholder = {
                    thumbnailImageSrc: "",
                    itemImageSrc: "",
                    title: "",
                    alt: ""
                };

                console.log("parent ID", parentId, targetSegment);

                if (parentId === 351) {
                    placeholder.thumbnailImageSrc = targetSegment.name === "mama"
                        ? "/assets/shop/minitopolis-clothing-mama-placeholder.jpg"
                        : "/assets/shop/minitopolis-clothing-placeholder.jpg"; // Default to 'children' or fallback

                    placeholder.itemImageSrc = placeholder.thumbnailImageSrc;
                    placeholder.alt = targetSegment.name === "mama"
                        ? "MAMA tøj " + "(" + this.variant.product.category.name + ")"
                        : "Børnetøj " + "(" + this.variant.product.category.name + ")"; // Default to 'children' or fallback
                } else if (parentId === 213) {
                    placeholder.thumbnailImageSrc = "/assets/shop/minitopolis-toy-placeholder.jpg";
                    placeholder.itemImageSrc = "/assets/shop/minitopolis-toy-placeholder.jpg";
                    placeholder.alt = "Legetøj " + "(" + this.variant.product.category.name + ")";
                } else if (parentId === 221) {
                    placeholder.thumbnailImageSrc = "/assets/shop/minitopolis-warm-clothing-placeholder.jpg";
                    placeholder.itemImageSrc = "/assets/shop/minitopolis-warm-clothing-placeholder.jpg";
                    placeholder.alt = "Overtøj " + "(" + this.variant.product.category.name + ")";
                } else if (parentId === 621) {
                    placeholder.thumbnailImageSrc = "/assets/shop/minitopolis-shoes-placeholder.jpg";
                    placeholder.itemImageSrc = "/assets/shop/minitopolis-shoes-placeholder.jpg";
                    placeholder.alt = "Fodtøj " + "(" + this.variant.product.category.name + ")";
                } else if (parentId === 376) {
                    // Logic for accessories images
                    placeholder.thumbnailImageSrc = targetSegment.name === "mama"
                        ? "/assets/shop/minitopolis-accessories-mama-placeholder.jpg"
                        : "/assets/shop/minitopolis-accessories-placeholder.jpg"; // Default to 'children' or fallback

                    placeholder.itemImageSrc = placeholder.thumbnailImageSrc;

                    placeholder.alt = targetSegment.name === "mama"
                        ? "Accessories til MAMA " + "(" + this.variant.product.category.name + ")"
                        : "Accessories til børn " + "(" + this.variant.product.category.name + ")"; // Default accessories alt
                } else if (parentId === 377) {
                    placeholder.thumbnailImageSrc = "/assets/shop/minitopolis-books-games-placeholder.jpg";
                    placeholder.itemImageSrc = "/assets/shop/minitopolis-books-games-placeholder.jpg";
                    placeholder.alt = "Bøger og spil " + "(" + this.variant.product.category.name + ")";
                } else if (parentId === 144) {
                    // Logic for equipment images
                    placeholder.thumbnailImageSrc = targetSegment.name === "mama"
                        ? "/assets/shop/minitopolis-equipment-mama-placeholder.jpg"
                        : "/assets/shop/minitopolis-equipment-placeholder.jpg"; // Default to 'children' or fallback

                    placeholder.itemImageSrc = placeholder.thumbnailImageSrc;

                    placeholder.alt = targetSegment.name === "mama"
                        ? "Udstyr til MAMA " + "(" + this.variant.product.category.name + ")"
                        : "Udstyr til børn " + "(" + this.variant.product.category.name + ")"; // Default equipment alt
                } else if (parentId === 620) {
                    // Logic for interior images
                    placeholder.thumbnailImageSrc = targetSegment.name === "mama"
                        ? "/assets/shop/minitopolis-interior-mama-placeholder.jpg"
                        : "/assets/shop/minitopolis-interior-placeholder.jpg"; // Default to 'children' or fallback

                    placeholder.itemImageSrc = placeholder.thumbnailImageSrc;

                    placeholder.alt = targetSegment.name === "mama"
                        ? "Interiør til MAMA " + "(" + this.variant.product.category.name + ")"
                        : "Interiør til børn " + "(" + this.variant.product.category.name + ")"; // Default interior alt
                } else if (categoryId === 132 || categoryId === 378) {
                    placeholder.thumbnailImageSrc = "/assets/shop/minitopolis-other-placeholder.jpg";
                    placeholder.itemImageSrc = "/assets/shop/minitopolis-other-placeholder.jpg";
                    placeholder.alt = categoryId === 132 ? "Andet kategori" : "Ukendt kategori";
                }

                // Assign the placeholder to formattedImages array
                this.formattedImages = [placeholder];
            }
        }
    };
</script>

<style>
    .p-galleria .p-galleria-content .p-galleria-thumbnails .p-galleria-thumbnails-content {
        justify-content: center !important;
    }
</style>