import "./bootstrap";
import { createApp } from "vue";
import mitt from "mitt";
import { translations } from "./translations";

const emitter = mitt();

// Components
import Toast from "./Pages/Common/Toast.vue";
import LightBox from "./Components/LightBox.vue";
import CreateVariant from "./Pages/CreateVariant.vue";
import MTAutocomplete from "./Pages/Common/MTAutocomplete.vue";
import UploadImage from "./Pages/Common/UploadImage.vue";
import Accordion from "./Pages/Common/Accordion.vue";
import ColorPicker from "./Pages/Common/ColorPicker.vue";
import SignUpFranchise from "./Pages/Common/SignUpFranchise.vue";
import SingleCalendar from "./Components/SingleCalendar.vue";
import AdvancedCalendar from "./Components/AdvancedCalendar.vue";
import { Calendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";
import AdvancedDatePicker from "./Components/AdvancedDatePicker.vue";
import Parcels from "./Components/Parcels.vue";
import InfoBox from "./Components/InfoBox.vue";
import SimpleAccordion from "./Components/SimpleAccordion.vue";
import SimplePickList from "./Pages/Common/SimplePickList.vue";
import WeekColor from "./Pages/Common/WeekColor.vue";
import ProductGallery from "./Shop/ProductGallery.vue";
// import Calendar from "./Components/Calendar.vue";
import ProductTableCard from "./Pages/Common/ProductTableCard.vue";
import ProductSorting from "./Components/ProductSorting.vue";
import ProductSearchButton from "./Components/ProductSearchButton.vue";
import TextareaEditor from "./Components/TextareaEditor.vue";
import BarChart from "./Charts/BarChart.vue";
import GroupedBarChart from "./Charts/GroupedBarChart.vue";
import DonutChart from "./Charts/DonutChart.vue";
import LineChart from "./Charts/LineChart.vue";
import ColumnChart from "./Charts/ColumnChart.vue";
import RadialBarChart from "./Charts/RadialBarChart.vue";
import SplineAreaChart from "./Charts/SplineAreaChart.vue";
import SparkBox from "./Charts/SparkBox.vue";
import AreaChart from "./Charts/AreaChart.vue";
import ColorBadge from "./Pages/Common/ColorBadge.vue";
import TagBadge from "./Pages/Common/TagBadge.vue";
// Pages
import ProductFormFields from "./Pages/Common/ProductFormFields.vue";
import ScannerPage from "./Pages/Common/ScannerPage.vue";
import BookingScannerPage from "./Pages/Common/BookingScannerPage.vue";
import ProductScannerPage from "./Pages/Common/ProductScannerPage.vue";
import StockBookingScanner from "./Pages/Common/StockBookingScanner.vue";
import StockPrepareBookingScanner from "./Pages/Common/StockPrepareBookingScanner.vue";
import BookingAddonPage from "./Pages/Common/BookingAddonPage.vue";
import DashboardEventsPage from "./Pages/Common/DashboardEventsPage.vue";
import ProductTable from "./Pages/Common/ProductTable.vue";
import TransferCentreNew from "./Pages/TransferCentreNew.vue";
// Booking Flow
import BookingFlow from "./Tenants/Bookings/BookingFlow.vue";
import Profile from "./Tenants/Bookings/Common/Profile.vue";
import LoginStep from "./Tenants/Bookings/LoginStep.vue";
import RegistrationStep from "./Tenants/Bookings/RegistrationStep.vue";
import ConceptStep from "./Tenants/Bookings/ConceptStep.vue";
import CalendarStep from "./Tenants/Bookings/CalendarStep.vue";
import FormStep from "./Tenants/Bookings/FormStep.vue";
import ReviewStep from "./Tenants/Bookings/ReviewStep.vue";
import SuccessStep from "./Tenants/Bookings/SuccessStep.vue";
import ProfileFormFields from "./Tenants/Bookings/Common/ProfileFormFields.vue";
import FinishFullProfile from "./Tenants/Bookings/Common/FinishFullProfile.vue";
import ProductMultiSelect from "./Shop/ProductMultiSelect.vue";
// Repos
import vClickOutside from "click-outside-vue3";
import * as Sentry from "@sentry/vue";
import VueApexCharts from "vue3-apexcharts";
import store from "./store";
import { createWebHistory, createRouter } from "vue-router";
import { swiffyslider } from "swiffy-slider";
import SubscribeNewsletter from "./Pages/Common/SubscribeNewsletter.vue";
import UnsubscribeNewsletter from "./Pages/Common/UnsubscribeNewsletter.vue";
// Shop
import AddProductButton from "./Shop/AddProductButton.vue";
import AddProductToListButton from "./Shop/AddProductToListButton.vue";
import CartButton from "./Shop/CartButton.vue";
import Cart from "./Shop/Cart.vue";
import Price from "./Shop/Price.vue";
import LoginPage from "./Shop/LoginPage.vue";
import SearchInput from "./Shop/SearchInput.vue";
import BrowsingProduct from "./Shop/BrowsingProduct.vue";
import CheckoutPage from "./Shop/CheckoutPage.vue";
import CheckoutPageSidebar from "./Shop/CheckoutPageSidebar.vue";
import CartProductList from "./Shop/CartProductList.vue";
import ProductList from "./Shop/ProductList.vue";
import LoadingButton from "./Shop/LoadingButton.vue";
import FavoriteButton from "./Shop/FavoriteButton.vue";
import SiteWideSearch from "./Shop/SiteWideSearch.vue";
import OverviewPage from "./Shop/OverviewPage.vue";
import MultiSelect from "./Shop/MultiSelect.vue";
import Modal from "./Shop/Modal.vue";
import MinMaxSlider from "./Shop/MinMaxSlider.vue";
import ProductCard from "./Shop/ProductCard.vue";
import ProductGrid from "./Shop/ProductGrid.vue";
import ProductFilters from "./Components/ProductFilters.vue";
import ProductCarousel from "./Shop/ProductCarousel.vue";
import LikeCounter from "./Shop/LikeCounter.vue";
import CartCounter from "./Shop/CartCounter.vue";
import Drawer from "./Pages/Common/Drawer.vue";
// Events
import EventCheckoutPage from "./Event/EventCheckoutPage.vue";
import ShopAgentPage from "./Shop/ShopAgentPage.vue";
import SimpleMap from "./Pages/SimpleMap.vue";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";

window.swiffyslider = swiffyslider;

window.addEventListener("load", () => {
    window.swiffyslider.init();
});

import "swiffy-slider/css";
import "./bootstrap.js";
import "primeicons/primeicons.css";

import Autocomplete from "./Shop/Filters/Autocomplete.vue";

const routes = [
    { path: "/", component: ConceptStep, name: "concept-step", meta: { depth: 1, requiresAuth: false } },
    { path: "/booking", component: ConceptStep, name: "concept-step", meta: { depth: 1, requiresAuth: false } },
    // { path: '/periode', component: PeriodStep, name: 'period-step', meta: { depth: 2, requiresAuth: false}},
    { path: "/kalender", component: CalendarStep, name: "calendar-step", meta: { depth: 3, requiresAuth: false } },
    { path: "/din-info", component: FormStep, name: "form-step", meta: { depth: 4, requiresAuth: false } },
    { path: "/login", component: LoginStep, name: "login-step", meta: { depth: 4, requiresAuth: false } },
    { path: "/registrer", component: RegistrationStep, name: "register-step", meta: { depth: 4, requiresAuth: false } },
    { path: "/booking-oversigt", component: ReviewStep, name: "review-step", meta: { depth: 5, requiresAuth: false } },
    { path: "/booking-succes", component: SuccessStep, name: "success-step", meta: { depth: 6, requiresAuth: false } },
    // Although it adds a (display none) DOM element, adding this to my route config seems to work for my purposes to suppress the warning. https://github.com/vuejs/router/issues/359
    { path: "/:pathMatch(.*)*", name: "not-found", component: { template: `<span style="display: none;"></span>` } }
];

const router = new createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.name) {
            return savedPosition;
        } else {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 300);
        }
    }
});

document.addEventListener("DOMContentLoaded", () => {
    const statusBar = document.querySelector("#status-bar");

    if (statusBar) {
        statusBar.classList.add("animate-customFadeInOut");
    }
});

function translate(key) {
    // Access translations for the current locale (e.g., 'en', 'da')
    const locale = document.documentElement.lang;

    if (translations[locale] && translations[locale][key]) {
        return translations[locale][key];
    }

    // If translation key is not found, return a fallback or the key itself
    return key;
}

// Import any polyfill to enable smoothscroll for JS APIs
import smoothscrollPolyfill from "smoothscroll-polyfill";
// Import this package to apply the smoothscroll to anchor links
//import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';
// (Unlike this package, smoothscroll-polyfill needs to be actively invoked: )
smoothscrollPolyfill.polyfill();

const app = createApp({});

// if (import.meta.env.VITE_APP_ENV === "production") {
//     Sentry.init({
//         app,
//         trackComponents: true,
//         dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
//         integrations: [
//             Sentry.browserTracingIntegration(),
//             Sentry.replayIntegration()
//         ],
//         // integrations: [
//         //     new Integrations.BrowserTracing({
//         //         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         //         tracingOrigins: ["minitopolis.dk", "*.minitopolis.dk", /^\//]
//         //     })
//         // ],
//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 1.0,
//         tracePropagationTargets: ["*.test", /^https:\/\/yourserver\.io\/api/, "minitopolis.dk", "*.minitopolis.dk", /^\//],
//         // Capture Replay for 10% of all sessions,
//         // plus for 100% of sessions with an error
//         replaysSessionSampleRate: 0.1,
//         replaysOnErrorSampleRate: 1.0
//     });
// }

app.component("minitopolis-autocomplete", MTAutocomplete);
app.component("product-form-fields", ProductFormFields);
app.component("scanner-page", ScannerPage);
app.component("booking-scanner-page", BookingScannerPage);
app.component("product-scanner-page", ProductScannerPage);
app.component("stock-booking-scanner", StockBookingScanner);
app.component("textarea-editor", TextareaEditor);
app.component("color-badge", ColorBadge);
app.component("stock-prepare-booking-scanner", StockPrepareBookingScanner);
app.component("booking-flow", BookingFlow);
app.component("toast", Toast);
app.component("upload-image", UploadImage);
app.component("booking-addon-page", BookingAddonPage);
app.component("dashboard-events-page", DashboardEventsPage);
app.component("accordion", Accordion);
app.component("color-picker", ColorPicker);
app.component("VCalendar", Calendar);
app.component("VDatePicker", DatePicker);
// app.component("calendar", Calendar);
app.component("single-calendar", SingleCalendar);
app.component("advanced-calendar", AdvancedCalendar);
app.component("profile", Profile);
app.component("login-step", LoginStep);
app.component("success-step", SuccessStep);
app.component("registration-step", RegistrationStep);
app.component("grouped-bar-chart", GroupedBarChart);
app.component("radial-bar-chart", RadialBarChart);
app.component("donut-chart", DonutChart);
app.component("column-chart", ColumnChart);
app.component("area-chart", AreaChart);
app.component("add-product-button", AddProductButton);
app.component("add-product-to-list-button", AddProductToListButton);
app.component("cart-button", CartButton);
app.component("cart", Cart);
app.component("login-page", LoginPage);
app.component("overview-page", OverviewPage);
app.component("cart-product-list", CartProductList);
app.component("product-list", ProductList);
app.component("browsing-product", BrowsingProduct);
app.component("search-input", SearchInput);
app.component("price", Price);
app.component("site-wide-search", SiteWideSearch);
app.component("checkout-page", CheckoutPage);
app.component("subscribe-newsletter", SubscribeNewsletter);
app.component("unsubscribe-newsletter", UnsubscribeNewsletter);
app.component("checkout-page-sidebar", CheckoutPageSidebar);
app.component("loading-button", LoadingButton);
app.component("favorite-button", FavoriteButton);
app.component("multi-select", MultiSelect);
app.component("modal", Modal);
app.component("light-box", LightBox);
app.component("profile-form-fields", ProfileFormFields);
// app.component('filters', Filters);
app.component("min-max-slider", MinMaxSlider);
app.component("autocomplete", Autocomplete);
app.component("event-checkout-page", EventCheckoutPage);
app.component("product-table", ProductTable);
app.component("spline-area-chart", SplineAreaChart);
app.component("spark-box", SparkBox);
app.component("tag-badge", TagBadge);
app.component("shop-agent-page", ShopAgentPage);
app.component("finish-full-profile", FinishFullProfile);
app.component("product-card", ProductCard);
app.component("product-grid", ProductGrid);
app.component("product-filters", ProductFilters);
app.component("product-sorting", ProductSorting);
app.component("product-search-button", ProductSearchButton);
app.component("product-table-card", ProductTableCard);
app.component("advanced-date-picker", AdvancedDatePicker);
app.component("info-box", InfoBox);
app.component("simple-accordion", SimpleAccordion);
app.component("transfer-centre-new", TransferCentreNew);
app.component("create-variant", CreateVariant);
app.component("simple-map", SimpleMap);
app.component("like-counter", LikeCounter);
app.component("cart-counter", CartCounter);
app.component("drawer", Drawer);
app.component("product-carousel", ProductCarousel);
app.component("product-multi-select", ProductMultiSelect);
app.component("simple-pick-list", SimplePickList);
app.component("week-color", WeekColor);
app.component("product-gallery", ProductGallery);
app.component("parcels", Parcels);

window.emitter = emitter;
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$translate = translate;

app.use(PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            prefix: "p",
            darkModeSelector: "light",
            cssLayer: false
        }
    }
});

app.use(vClickOutside);
app.use(VueApexCharts);
app.use(store);
app.use(router);
app.mount("#app");

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.state.bookingUuid && !store.state.authenticatedUser) {
            next({
                name: "concept-step",
                query: { redirect: to.fullPath }
            });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});
