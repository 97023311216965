<template>
    <div class="relative bg-white flex flex-col w-full md:w-2/5">
        <div class="md:mr-auto md:max-w-xl md:fixed p-6 lg:p-8">
            <h3>Ordreoversigt</h3>
            <div class="products-inner p-0 flex flex-col h-full" style="height: calc(100vh - 400px)">
                <!-- Display message for empty cart -->
                <div v-if="isCartEmpty"
                     class="text-red-600 text-sm mt-4 flex flex-col items-center justify-center">
                    <p>Din kurv er tom. Tilføj varer for at fortsætte til betaling.</p>
                    <button class="button border-button small-button mt-2" @click="goBack">
                        Gå tilbage til shoppen
                    </button>
                </div>
                <div class="products m-1 overflow-auto flex-auto relative min-h-[200px]">
                    <cart-product-list></cart-product-list>
                </div>
                <div class="flex flex-col gap-1 border-y border-stone-100 py-4 my-4">
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span>Produkttotal</span>
                            </div>
                        </div>
                        <price :price="$store.state.cart.subtotal"></price>
                    </div>
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span>Fragt</span>
                            </div>
                        </div>
                        <price :price="deliveryPrice"></price>
                    </div>
                </div>
                <div class="flex flex-col gap-1 mb-2">
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span class="font-bold text-base">Total inkl. moms</span>
                            </div>
                        </div>
                        <price :price="totalWithDelivery" class="font-bold text-base"></price>
                    </div>
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span>Moms udgør</span>
                            </div>
                        </div>
                        <price :price="$store.state.cart.tax_amount"></price>
                    </div>
                    <div class="flex justify-between space-x-4 text-sm">
                        <div class="flex space-x-4">
                            <div class="flex flex-col">
                                <span>Subtotal ekskl. moms</span>
                            </div>
                        </div>
                        <price :price="$store.state.cart.subtotal - $store.state.cart.tax_amount"></price>
                    </div>
                </div>
                <!--Terms-->
                <div v-if="!inCart" class="py-4">
                    <label class="flex p-2 border border-gray-300 bg-stone-100 hover:bg-stone-50"
                           for="accepts_trade_terms">
                        <span class="flex items-center h-5">
                            <input id="accepts_trade_terms" name="accepts_trade_terms" type="checkbox"
                                   class="form-input-checkbox" v-model="acceptsTradeTerms"
                                   @change="emitCheckboxState" />
                        </span>
                        <span class="ml-3 text-sm select-none">
                            <span class="font-medium text-gray-700"
                            >Jeg accepterer
                                <a href="https://minitopolis.dk/handelsbetingelser#/" class="a-link"
                                   target="_blank">handelsbetingelserne</a>
                                og giver samtykke til at Minitopolis behandler mine
                                <a href="https://minitopolis.dk/persondatapolitik#/" class="a-link" target="_blank">persondata</a>

                            </span>
                        </span>
                    </label>
                </div>
                <!--                // TODO vi skal disable knappen hvis et eller flere produkter er blevet solgt i mellemtiden-->
                <!--                <div class="w-full border border-gray-300 p-2 mt-2 mb-4"-->
                <!--                     v-if="checkoutForm && checkoutForm.delivery_method">-->
                <!--                    <div class="text-sm font-bold">Forsendelse</div>-->
                <!--                    <div class="flex items-center w-full">-->
                <!--                        <div v-if="checkoutForm.delivery_method === 'store_pickup'">{{ checkoutForm.delivery_method }}-->
                <!--                        </div>-->
                <!--                        <div v-if="checkoutForm.delivery_method === 'keep_in_store'">{{ checkoutForm.delivery_method-->
                <!--                            }}-->
                <!--                        </div>-->
                <!--                        <div v-if="checkoutForm.delivery_method === 'home_delivery'"-->
                <!--                             class="flex items-center justify-between gap-2 w-full">-->
                <!--                            <div>-->
                <!--                                PostNord - Hjemmelevering-->
                <!--                            </div>-->
                <!--                            <div class="text-sm text-gray-500">-->
                <!--                                59,00 kr.-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div v-if="checkoutForm.delivery_method === 'pickup_point'">{{ checkoutForm.delivery_method }}-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <button class="button button-purple checkout-button !rounded-none" @click="submit"
                        :disabled="isCartEmpty || hasUnsellableProducts">
                    Godkend ordren og gå til betaling
                </button>
                <div class="flex flex-col justify-center space-y-2 mt-4">
                    <div class="flex justify-center -mx-1 flex-wrap">
                        <img loading="lazy" class="w-20 bg-no-repeat m-1" src="/assets/payments/mobile-pay.svg"
                             alt="Mobile Pay logo">
                        <img loading="lazy" class="w-12 bg-no-repeat m-1" src="/assets/payments/visa.svg"
                             alt="Visa logo">
                        <img loading="lazy" class="w-10 bg-no-repeat m-1" src="/assets/payments/visa-electron.svg"
                             alt="Visa Electron logo">
                        <img loading="lazy" class="w-12 bg-no-repeat m-1" src="/assets/payments/mastercard.svg"
                             alt="Mastercard logo">
                        <img loading="lazy" class="w-12 bg-no-repeat m-1" src="/assets/payments/maestro.svg"
                             alt="Maestro logo">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CartProductList from "./CartProductList.vue";
    import Price from "./Price.vue";
    import { mapGetters } from "vuex";

    export default {
        components: {
            CartProductList, Price
        },
        props: {
            inCart: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                acceptsTradeTerms: false // Local state for the checkbox
            };
        },
        computed: {
            ...mapGetters(["checkoutErrors", "checkoutForm"]),
            deliveryPrice() {
                console.log("nn", this.checkoutForm);
                const deliveryMethod = this.checkoutForm.delivery_method; // Replace with the correct state path, if needed.

                if (deliveryMethod === "pickup_point") {
                    return 2900; // Price for pickup_point
                } else if (deliveryMethod === "home_delivery") {
                    return 5900; // Price for home_delivery
                } else if (deliveryMethod === "keep_in_store" || deliveryMethod === "store_pickup") {
                    return 0; // Free for keep_in_store or store_pickup
                } else {
                    return 0; // Default to free if no delivery method is selected
                }
            },
            // Calculate total including delivery
            totalWithDelivery() {
                const cartSubtotal = this.$store.state.cart.subtotal || 0; // Get the current cart subtotal
                const deliveryCost = this.deliveryPrice; // Get the delivery price
                return cartSubtotal + deliveryCost; // Add them together
            },
            isCartEmpty() {
                const rawItems = this.$store.state.cart.items || {};
                const items = Object.values(rawItems); // Convert proxy object to array
                return items.length === 0; // Cart is empty if there are no items
            },
            hasUnsellableProducts() {
                const rawItems = this.$store.state.cart.items || {};
                const items = Object.values(rawItems); // Convert proxy object to array

                return items.some(product => {
                    const isSold = product.extra_info?.is_sold;
                    const outOfStock = product.out_of_stock;

                    return (isSold && !isNaN(Date.parse(isSold))) || outOfStock;
                });
            }
        },
        mounted() {
            // Fetch latest cart items when this component is mounted
            this.$store.dispatch("getCartItems");
        },
        methods: {
            submit() {
                axios.post("/shop/checkout", this.checkoutForm).then((response) => {
                    window.location.href = response.data.url;
                })
                    .catch(error => {
                        console.log("error", error.response.data.errors);
                    });
            },
            emitCheckboxState() {
                // Emit the state of the checkbox
                this.emitter.emit("checkbox-state-changed", this.acceptsTradeTerms);
            },
            goBack() {
                // If the user has a history to go back
                if (window.history.length > 1) {
                    window.history.back(); // Go back to the previous page
                } else {
                    this.$router.push("/shop"); // Redirect to the shop page (fallback)
                }
            }

        }
    };

</script>
