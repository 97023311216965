<template>
    <div class="flex flex-col gap-3">
        <div class="flex items-center gap-1">
            <button v-if="alreadyInCart"
                    :class="{ 'already-in-cart': alreadyInCart, 'shop-button p-1.5 xs:p-2.5' : buttonType === 'small', 'button button-purple h-12 small-button' : buttonType === 'large' }"
                    class="flex items-center justify-between" @click="removeFromCart" title="Fjern fra kurv">
                <svg xmlns="http://www.w3.org/2000/svg"
                     class="flex-shrink-0 min-w-0 w-4 xs:w-5 -mt-0.5"
                     :class="{ '-mt-1' : buttonType === 'large' }" viewBox="0 0 32 32">
                    <path
                        d="M6.559,0h9.6c4.533,0,4.987,2.12,5.293,4.707l1.2,10c.387,3.28-.627,5.96-5.293,5.96H5.373c-4.68,0-5.693-2.68-5.293-5.96l1.2-10C1.573,2.12,2.026,0,6.559,0Z"
                        transform="translate(4.641 8.667)" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5" />
                    <path d="M0,8V3.333A3.149,3.149,0,0,1,3.333,0h4a3.149,3.149,0,0,1,3.333,3.333V8"
                          transform="translate(10.667 2.667)" fill="none" stroke="currentColor" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="1.5" />
                    <path d="M16.547,0H0" transform="translate(10.667 22.707)" fill="none" stroke="currentColor"
                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </svg>
            </button>
            <button
                :class="{ 'shop-button p-1.5 xs:p-2.5 ' : buttonType === 'small', 'button shop-button-purple h-12 small-button' : buttonType === 'large' }"
                class="flex items-center justify-between text-topolis-blue-700 fill-topolis-purple-400 focus:no-underline focus:outline-none focus:outline-0"
                @click="addToCart" disabled
                :title="titleCondition"
                :disabled="normalizedVariant.sold_at || reachedStockLimit">
                <span v-if="normalizedVariant.sold_at">
                    <span class="uppercase text-xxs xxs:text-xs md:text-sm font-medium">Solgt</span>
                </span>
                <span v-else class="inline-flex items-center justify-between">
                    <span class="uppercase mr-0.5 text-xxs xxs:text-xs md:text-sm font-medium leading-3">{{ labelText
                        }}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 xs:w-5 -mt-0.5"
                         :class="{ '-mt-1' : buttonType === 'large' }"
                         viewBox="0 0 32 32">
                        <path
                            d="M6.559,0h9.6c4.533,0,4.987,2.12,5.293,4.707l1.2,10c.387,3.28-.627,5.96-5.293,5.96H5.373c-4.68,0-5.693-2.68-5.293-5.96l1.2-10C1.573,2.12,2.026,0,6.559,0Z"
                            transform="translate(4.641 8.667)" fill="fillColor" stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="1.5" />
                        <path d="M0,8V3.333A3.149,3.149,0,0,1,3.333,0h4a3.149,3.149,0,0,1,3.333,3.333V8"
                              transform="translate(10.667 2.667)" fill="none" stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="1.5" />
                        <path d="M16.547,0H0" transform="translate(10.667 22.707)" fill="none" stroke="currentColor"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    </svg>

                    <!--                <svg xmlns="http://www.w3.org/2000/svg"-->
                    <!--                     class="flex-shrink-0 min-w-0 w-4 xs:w-5 -mt-0.5"-->
                    <!--                     :class="{ '-mt-1' : buttonType === 'large' }" viewBox="0 0 32 32">-->
                    <!--                    <path-->
                    <!--                        d="M6.559,0h9.6c4.533,0,4.987,2.12,5.293,4.707l1.2,10c.387,3.28-.627,5.96-5.293,5.96H5.373c-4.68,0-5.693-2.68-5.293-5.96l1.2-10C1.573,2.12,2.026,0,6.559,0Z"-->
                    <!--                        transform="translate(4.641 8.667)" fill="none" stroke="currentColor" stroke-linecap="round"-->
                    <!--                        stroke-linejoin="round" stroke-width="1.5" />-->
                    <!--                    <path d="M0,8V3.333A3.149,3.149,0,0,1,3.333,0h4a3.149,3.149,0,0,1,3.333,3.333V8"-->
                    <!--                          transform="translate(10.667 2.667)" fill="none" stroke="currentColor" stroke-linecap="round"-->
                    <!--                          stroke-linejoin="round" stroke-width="1.5" />-->
                    <!--                    <path d="M16.547,0H0" transform="translate(10.667 22.707)" fill="none" stroke="currentColor"-->
                    <!--                          stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />-->
                    <!--                </svg>-->
                </span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            variant: {
                required: true
            },
            buttonType: {
                required: true,
                type: String
            },
            isOnProductPage: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        data() {
            return {
                normalizedVariant: {} // Store the normalized variant here
            };
        },
        mounted() {
            // Normalize the variant prop (whether it's a string or already an object)
            this.normalizedVariant = typeof this.variant === "string"
                ? JSON.parse(this.variant) // Parse the JSON string
                : this.variant; // Already a valid object

            console.log("Normalized Variant:", this.normalizedVariant);
        },
        computed: {
            alreadyInCart() {
                return this.$store.getters.cartProductIds.includes(this.normalizedVariant.id);
            },
            titleCondition() {
                if (this.normalizedVariant.sold_at) {
                    return "Varen er solgt";
                } else if (this.reachedStockLimit) {
                    return "Der er ikke flere på lager";
                }
                return "Læg i kurv";
            },
            labelText() {
                // Access cart items from the Vuex store
                const cartItems = this.$store.state.cart.items;

                // Find the product in the cart by its ID
                const cartProduct = Object.values(cartItems).find(
                    (item) => item.id === this.normalizedVariant.id
                );

                // If the product is in the cart, determine the label based on quantity
                if (cartProduct) {
                    return cartProduct.quantity > 1
                        ? `${cartProduct.quantity} tilføjet`
                        : "Tilføjet";
                }

                // Default label
                return "Læg i kurv";
            },
            reachedStockLimit() {
                const cartItems = this.$store.state.cart.items;

                const cartProduct = Object.values(cartItems).find(
                    (item) => item.id === this.normalizedVariant.id
                );

                return cartProduct
                    ? cartProduct.quantity >= this.normalizedVariant.stock_level
                    : false;
            }
        },
        methods: {
            addToCart(e) {
                // if (this.alreadyInCart) return;
                e.preventDefault();

                this.$store.dispatch("addToCart", {
                    variant: this.normalizedVariant
                });
            },
            removeFromCart(e) {
                if (!this.alreadyInCart) return;
                e.preventDefault();

                const foundProduct = this.$store.getters.cartProducts.find((variant) => {
                    return variant.id === this.normalizedVariant.id;
                });

                this.$store.dispatch("removeFromCart", foundProduct.hash);
            }
        }
    };
</script>
